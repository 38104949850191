import './App.scss';
import rics from './rics.png';
import rics2 from './rics-2.jpg';
import salostowitz from './salostowitz.jpg';

function App() {
  return (
    <div className="App">
      <header className="header">
        <h1>Salostowitz</h1>
        <h2>Immobilienberatung</h2>
        <p>Ihr Sachverständigen&shy;büro für Immobilien&shy;bewertung und Transaktions&shy;beratung</p>
      </header>
      <section className="about">
        <p>Über 2 Jahrzehnte Erfahrung in den Bereichen Immobilien&shy;bewertung, Standort&shy;analyse und Transaktions&shy;beratung für gewerblich und wohnwirt&shy;schaftlich genutzte Liegenschaften in Deutschland.</p>
        <h3>Leistungsspektrum:</h3>
        <ul>
          <li>Verkehrswert-/Marktwert&shy;ermittlung von bebauten und unbebauten Grundstücken zum Zwecke des An- und Verkaufs sowie der gerichtlichen und außergericht&shy;lichen Vermögens&shy;auseinander&shy;setzungen</li>
          <li>Markt- und Standort&shy;analyse</li>
          <li>Beratung und Unterstützung im Bereich von Immobilien&shy;transaktionen</li>
        </ul>
      </section>
      <section className="contact">
        <h2>Kontakt</h2>
        <div className="box">
          <div className="address">
            <p>Salostowitz Immobilienberatung<br />Rodergasse 15<br />65510 Idstein<br />Mobil: <a href="tel:+491722889967">+49 172 28 89 967</a></p>
            <p>Dipl.-Ing. Dipl.-Wirt.-Ing.<br />Peter Salostowitz FRICS<br /><a href="mailto:salostowitz@sib-idstein.de">salostowitz@sib-idstein.de</a></p>
            <p><img className="rics-2" src={rics2} alt="RICS Registered Valuer" /></p>
          </div>
          <div className="picture">
            <img src={salostowitz} alt="Peter Salostowitz" />
          </div>
        </div>
        <div className="bubble">
          <span className="clipper"></span>
          <p>Salostowitz Immobilienberatung — Ihr zuverlässiger Partner bei der Bewertung, Transaktion und Analyse von Immobilien</p>
        </div>
      </section>
      <footer>
        <div className="link">
          <a href="https://www.sib-idstein.de/">www.sib-idstein.de</a>
          <img className="rics" src={rics} alt="RICS | the mark of property professionalism worldwide" />
        </div>
        <div className="colophon">
          <div className="col">
            <h3>Angaben gemäß §5 TMG:</h3>
            <p>Salostowitz Immobilienberatung<br />Rodergasse 15<br />65510 Idstein</p>
            <p>Telefon: <a href="tel:+491722889967">+49 172 28 89 967</a><br />Email: <a href="mailto:info@sib-idstein.de">info@sib-idstein.de</a></p>
          </div>
          <div className="col">
            <h3>Vertreten durch:</h3>
            <p>Inhaber:<br />Dipl.Ing. Dipl.Wirt.-Ing. Peter Salostowitz FRICS</p>
            <h3>Umsatzsteuer:</h3>
            <p>Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz:<br />USt.-IdNr: DE 240 154 753</p>
          </div>
          <div className="col wide">
            <h3>Haftung für Inhalte</h3>
            <p>Als Dienste&shy;anbieter sind wir gemäß §7 Abs. 1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Dienste&shy;anbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p>
            <p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechts&shy;verletzung möglich. Bei Bekannt&shy;werden von entsprechenden Rechts&shy;verletzungen werden wir diese Inhalte umgehend entfernen.</p>
          </div>
          <div className="col wide">
            <h3>Urheberrecht</h3>
            <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.</p>
            <p>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechts&shy;verletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
